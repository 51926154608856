<template>
   <b-dropdown id="dropdown-1" size="sm" :text="$i18n.locale" class="m-md-2 text-uppercase" menu-class="min-width-0" toggle-class="text-uppercase fs-14">
    <button class="d-block px-3" @click="changeLocale('en')">EN</button>
    <button class="d-block px-3" @click="changeLocale('ru')">RU</button>
  </b-dropdown> 
</template>
    
<script>
export default {
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale
      localStorage.setItem('tigpok_locale', locale)
    }
  },

  mounted() {
    this.$i18n.locale = localStorage.getItem('tigpok_locale') || 'en'
  }
}
</script>