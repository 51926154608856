import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  en: {
    // General
    loading: 'Loading...',
    group: 'Group',
    white_theme:'White theme',
    withdrawal : 'Withdrawal',
    withdraw_funds: 'Withdraw funds',
    amount: 'Amount',
    minimum_amount_withdraw :'The minimum withdrawal amount is 100.',
    withdrawal_wallet_alert: 'Funds will be transferred to the specified wallet. In case of incorrect data, the organizers are not responsible for any losses.',
    withdraw: 'Withdraw',
    wallet_address: 'Wallet address',
    payments: 'Payments',
    account: 'Account',
    no_payments: 'No payments',
    change_nick: 'Change nickname',
    username: 'Username',
    change_password: 'Change password',
    old_password: 'Old password',
    new_password: 'New password',
    confirm_password: 'Confirm password',
    save: 'Save',

    // Header
    game_rules: 'Rules',
    add_money: 'Add money',

    how_to_buy_usdt: 'How to buy USDT (using Trust Wallet as an example)',
    add_money_to_balance: 'Add funds to balance',
    add_money_tip: 'You will be redirected to the cryptocloud payment page.',
    pay: 'Pay',
    go_home: 'Go home',
    close: 'Close',
    log_out: 'Log Out',
    dashboard: 'Dashboard',

    // Game start and setup
    startVideo: 'Start video',
    hi: 'Hi',
    turnOnCamera: 'To start the game please turn on the camera',
    play: 'Play',
    bonusGame: 'Bonus Game',
    chooseBet: 'Choose bet',
    cancel: 'Cancel',
    betRequiredForBonusGame: 'A bet of 5 is required to play the bonus game. The bet will automatically be set to 5 when you select the bonus game.',
    
    // Game states
    waitingForPlayers: 'Waiting for players',
    tryingToConnect: 'Trying to connect',
    videoUnavailable: 'The game has started but the video is not available, use the chat',
    stop: 'Stop',
    waitForResponse: 'Wait for response',
    waitForCall: 'Wait for call',
    waitForDiscussion: 'Waiting for {name1} and {name2} to finish their discussion.',
    
    // Chat
    writeMessage: 'Write message',
    
    // Exile phase
    chooseWhoseBetToSplit: 'Choose whose bet to split',
    chooseAll: 'Choose all',
    youVotedAgainst: 'You voted against {name}',
    youVotedAgainstMultiple: 'You voted against {names}',
    
    // Results
    youWon: 'You won!!',
    youLost: 'You lost',
    draw: 'Draw',
    decisions: 'Decisions',
    player: 'Player',
    against: 'Against',

    // Tips
    tips: {
      tip1: "Success is the art of negotiating with fate",
      tip2: "The best strategy in Tigpok: play like a pro, but bet like a beginner.",
      tip3: "There is always a best strategy in Tigpok - just don't let your opponents know what that strategy is.",
      tip4: "In the Tigpok game, as in politics: personal charm is a weapon, and lies are a tool.",
      tip5: "It is not surprising that those who tell the truth often win in Tigpok, because the truth is also a weapon.",
      tip6: "Tigpok is a theater, and all players are actors.",
      tip7: "Psychology is an important tool in recognizing lies, but even more important in recognizing the truth.",
      tip8: "The ability to come to an agreement with any person is one of the most important traits in a person's success; you just need to monetize it.",
      tip9: "Trust is not an important strategy in life, and especially not in this game.",
      tip10: "Quotes are the haiku of propagandists.",
      tip11: "Life is an eternal Tigpok, you always choose who to trust. Why not make money from it?",
      tip12: "Tigpok is a mirror in which we see the reflection of our decisions.",
      tip13: "At Tigpok we explore the world not only outside, but also inside ourselves.",
      tip14: "Tigpok is a path to understanding life, where the rules are created and changed by us.",
      tip15: "Tigpok teaches us to make decisions and learn from mistakes, just like in real life.",
      tip16: "Through Tigpok we can experience different roles and understand others' experiences, which promotes empathy and understanding.",
      tip17: "People trust more those who are successful.",
      tip18: "Tigpok confirms the end and justifies the means.",
      tip19: "Sometimes a trick is the only way to get what you deserve.",
      tip20: "Success is not just an accident.",
      tip21: "People love to communicate, deceive each other and make money from communication and lies - all of this is in Tigpok.",
      tip22: "Convincing people to trust you and then making money from it is what politics and Tigpok have in common.",
      tip23: "The world has many problems, but even more opportunities.",
      tip24: "Opportunities always lie with those who seek them.",
      tip25: "You don't need great opportunities to get started. Start with what you have and they will come as you move forward.",
      tip26: "Knowing yourself helps you change yourself, adaptation is the key to survival in Tigpok.",
      tip27: "A bird relies on its wings, a man on strategy.",
      tip28: "The best moment to act is now.",
      tip29: "The desire for victory without a plan is just a dream.",
      tip30: "Luck is a choice, not a result of circumstances.",
      tip31: "We learn from mistakes, we grow with experience.",
      tip32: "The weak look for excuses, the strong prepare them in advance.",
      tip33: "Tired of sitting without money? Get up!",
      tip34: "Don't be afraid to fight someone who doesn't know how.",
      tip35: "You live as well as you can negotiate."
    }
  },
  ru: {
    // General
    loading: 'Загрузка...',
    group: 'Группа',
    white_theme:'Белая тема',
    withdrawal: 'Вывод средств',
    withdraw_funds: 'Вывести средства',
    amount: 'Сумма',
    minimum_amount_withdraw :'Минимальная сумма снятия составляет 100.',
    withdrawal_wallet_alert: 'Средства будут переведены на указанный кошелек. В случае указания неверных данных организаторы не несут ответственности за любые потери.',
    withdraw: 'Вывести',
    wallet_address: 'Номер кошелька',
    payments: 'Платежи',
    no_payments: 'Платежи отсутствуют',
    account: 'Аккаунт',
    change_nick: 'Изменить имя пользователя',
    username: 'Имя пользователя',
    change_password: 'Сменить пароль',
    old_password: 'Старый пароль',
    new_password: 'Новый пароль',
    confirm_password: 'Подтвердите пароль',
    save: 'Сохранить',

    // Header
    game_rules: 'Правила',
    add_money: 'Пополнить счет',

    how_to_buy_usdt: 'Как купить USDT (на примере кошелька Trust Wallet)',
    add_money_to_balance: 'Пополнить баланс',
    add_money_tip: 'Вы будете перенаправлены на страницу оплаты cryptocloud.',
    pay: 'Пополнить',
    go_home: 'На главную',
    close: 'Закрыть',
    log_out: 'Выйти',
    dashboard: 'Панель управления',

    // Game start and setup
    startVideo: 'Включить камеру',
    hi: 'Привет',
    turnOnCamera: 'Для начала игры включите камеру',
    play: 'Играть',
    bonusGame: 'Бонус Игра',
    chooseBet: 'Выберите ставку',
    cancel: 'Отмена',
    betRequiredForBonusGame: 'Для начала игры необходимо сделать ставку в 5 монет. Ставка автоматически будет установлена в 5 монет при выборе бонусной игры.',
    
    // Game states
    waitingForPlayers: 'Ожидание игроков',
    tryingToConnect: 'Попытка подключения',
    videoUnavailable: 'Игра началась, но видео недоступно, используйте чат',
    stop: 'Стоп',
    
    // Chat
    writeMessage: 'Написать сообщение',
    
    // Exile phase
    chooseWhoseBetToSplit: 'Выберите чью ставку разделить',
    chooseAll: 'Выбрать всех',
    youVotedAgainst: 'Вы проголосовали против {name}',
    youVotedAgainstMultiple: 'Вы проголосовали против {names}',
    
    // Results
    youWon: 'Вы выиграли!!',
    youLost: 'Вы проиграли',
    draw: 'Ничья',
    decisions: 'Решения',
    player: 'Игрок',
    against: 'Против',

    // Tips
    tips: {
      tip1: "Успех - это искусство переговоров с судьбой",
      tip2: "Лучшая стратегия в Tigpok: играй как профессионал, но ставь как новичок.",
      tip3: "В Tigpok всегда есть лучшая стратегия - просто не дай противникам узнать, какая именно.",
      tip4: "В игре Tigpok, как и в политике: личное обаяние - это оружие, а ложь - инструмент.",
      tip5: "Неудивительно, что те, кто говорит правду, часто побеждают в Tigpok, потому что правда - тоже оружие.",
      tip6: "Tigpok - это театр, а все игроки - актеры.",
      tip7: "Психология - важный инструмент в распознавании лжи, но еще важнее в распознавании правды.",
      tip8: "Умение договориться с любым человеком - одна из важнейших черт в успехе человека; нужно только монетизировать это.",
      tip9: "Доверие - не важная стратегия в жизни, а особенно в этой игре.",
      tip10: "Цитаты - это хайку пропагандистов.",
      tip11: "Жизнь - вечный Tigpok, ты всегда выбираешь, кому доверять. Почему бы не заработать на этом?",
      tip12: "Tigpok - это зеркало, в котором мы видим отражение наших решений.",
      tip13: "В Tigpok мы исследуем мир не только снаружи, но и внутри себя.",
      tip14: "Tigpok - это путь к пониманию жизни, где правила создаются и меняются нами.",
      tip15: "Tigpok учит нас принимать решения и учиться на ошибках, как в реальной жизни.",
      tip16: "Через Tigpok мы можем испытать разные роли и понять опыт других, что способствует эмпатии и пониманию.",
      tip17: "Люди больше доверяют тем, кто успешен.",
      tip18: "Tigpok подтверждает цель и оправдывает средства.",
      tip19: "Иногда хитрость - единственный способ получить то, что ты заслуживаешь.",
      tip20: "Успех - это не просто случайность.",
      tip21: "Люди любят общаться, обманывать друг друга и зарабатывать на общении и лжи - все это есть в Tigpok.",
      tip22: "Убеждать людей доверять тебе, а потом зарабатывать на этом - то, что объединяет политику и Tigpok.",
      tip23: "В мире много проблем, но еще больше возможностей.",
      tip24: "Возможности всегда лежат с теми, кто их ищет.",
      tip25: "Не нужны большие возможности, чтобы начать. Начни с того, что есть, и они придут по мере движения вперед.",
      tip26: "Знание себя помогает изменить себя, адаптация - ключ к выживанию в Tigpok.",
      tip27: "Птица полагается на крылья, человек на стратегию.",
      tip28: "Лучший момент для действия - сейчас.",
      tip29: "Желание победы без плана - просто мечта.",
      tip30: "Удача - это выбор, а не результат обстоятельств.",
      tip31: "Мы учимся на ошибках, растем с опытом.",
      tip32: "Слабые ищут оправдания, сильные готовят их заранее.",
      tip33: "Надоело сидеть без денег? Встань!",
      tip34: "Не бойся драться с тем, кто не умеет.",
      tip35: "Живешь настолько хорошо, насколько умеешь договариваться."
    }
  }
}

export default new VueI18n({
  locale: 'en', // set default locale
  fallbackLocale: 'en', // set fallback locale
  messages
}) 